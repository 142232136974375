<template>
  <div>
    <v-card>
      <v-card-title>
        Termo LGPD
      </v-card-title>

      <v-divider class="mt-0"></v-divider>

      <v-card-text class="d-flex flex-row-reverse pb-4">
        <v-btn
          class="mr-4"
          outlined
          @click="clearFilter()"
        >
          <v-icon
            size="25"
            class="mr-2"
          >
            {{ icons.mdiFilterRemoveOutline }}
          </v-icon>
          Limpar Filtros
        </v-btn>

        <v-btn
          color="warning"
          class="ml-2 mr-6"
          outlined
          v-bind="attrs"
          v-on="on"
          @click="exportCsv"
        >
          <v-icon size="30">
            {{ icons.mdiDownload }}
          </v-icon>
        </v-btn>

        <v-btn
          color="info"
          class="mr-4"
          outlined
          @click="openModal('filter')"
        >
          <v-icon
            size="25"
            class="mr-2"
          >
            {{ icons.mdiFilterPlusOutline }}
          </v-icon>
          Filtro
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="termLgpdList"
        :loading="isLoadingTableData"
        loading-text="Carregando dados..."
        fixed-header
        :height="heightDynamic"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="item.id === modalOptions.loading? false : true"
            medium
            class="me-2"
            @click="editTermLgpd(item)"
          >
            {{ icons.mdiTextBoxSearch }}
          </v-icon>
          <v-progress-circular
            v-if="item.id === modalOptions.loading? true : false"
            indeterminate
            :size="25"
            color="gray"
          ></v-progress-circular>
          <v-icon
            v-if="item.id === modalOptions.loading2? false : true"
            medium
            class="me-2"
            @click="sendingSignedDocto(item)"
          >
            {{ icons.mdiFileSendOutline }}
          </v-icon>
          <v-progress-circular
            v-if="item.id === modalOptions.loading2? true : false"
            indeterminate
            :size="25"
            color="gray"
          ></v-progress-circular>
        </template>
        <template v-slot:no-data>
          {{ noDataText }}
        </template>
      </v-data-table>

      <v-divider class="mt-4"></v-divider>

      <v-card-text class="pt-2 pb-1">
        <v-row>
          <v-col
            lg="12"
            cols="12"
            class="d-flex justify-center"
          >
            <v-pagination
              v-model="pageOptions.page"
              total-visible="6"
              :length="pageOptions.pageCount"
              @input="updatePage"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="modalOptions.modal"
      width="900"
    >
      <lgpd-modal
        :close-modal="closeModal"
        :is-edit="modalOptions.editItem"
        :is-valid="modalOptions.validItem"
        :is-disable="modalOptions.disableItem"
        :is-remake="modalOptions.remakeItem"
        :send-contract="modalOptions.sendContract"
        :term-lgpd-item="modalOptions.termLgpdItem"
        :user-data="userData"
        :initialize="initialize"
        :updated-table="updatedTable"
      />
    </v-dialog>

    <v-dialog
      v-model="showFilter"
      width="700px"
    >
      <LgpdFilter
        :list-store="listStore"
        :list-region="listRegion"
        @filteredData="$event => setTableData($event)"
        @filteredBody="$event => setBody($event)"
        @close="showFilter = false"
      >
      </LgpdFilter>
    </v-dialog>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'

import {
  mdiAccountOutline,
  mdiCalendar,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiDownload,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiFileSendOutline,
  mdiFilter,
  mdiFilterMenu,
  mdiFilterPlusOutline,
  mdiFilterRemove,
  mdiFilterRemoveOutline,
  mdiLoading,
  mdiMagnify,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiTextBoxSearch,
  mdiWhatsapp,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import LgpdFilter from './LgpdFilter.vue'
import LgpdModal from './LgpdModal.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    LgpdModal,
    LgpdFilter,
  },

  mixins: [formatters],

  data: () => ({
    showFilter: false,
    bodyFilter: {},
    icons: {
      mdiDownload,
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiPencilOutline,
      mdiFileDocumentOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiExportVariant,
      mdiAccountOutline,
      mdiFilter,
      mdiFilterRemove,
      mdiWhatsapp,
      mdiCalendar,
      mdiFilterMenu,
      mdiLoading,
      mdiEyeOutline,
      mdiMagnify,
      mdiTextBoxSearch,
      mdiFileSendOutline,
      mdiFilterPlusOutline,
      mdiFilterRemoveOutline,
    },

    listStore: [],
    listRegion: [],

    select_options: [
      {
        text: 'NOME DO CLIENTE',
        value: 'client',
      },
      {
        text: 'NÚMERO DA PROPOSTA',
        value: 'proposal',
      },
      {
        text: 'CPF DO CLIENTE',
        value: 'document',
      },
      {
        text: 'NOME DO VENDEDOR',
        value: 'vendor_name',
      },
    ],
    typeFilterSelected: '',
    filterOptions: {
      input_value: '',
      validate: true,
      proposal_number: '',
      cpf_cnpj: '',
      client_name: '',
      vendor_name: '',
    },

    termLgpdList: [],
    noDataText: 'Nenhum registro encontrado',
    heightDynamic: '600px',
    isLoadingTableData: false,
    headers: [
      { text: 'DATA', value: 'contract_date' },
      { text: 'PROPOSTA', value: 'proposal_number' },
      { text: 'CLIENTE', value: 'client_name' },
      { text: 'VENDEDOR', value: 'vendor_name' },

      // { text: 'CPF/CNPJ', value: 'cpf_cnpj' },
      { text: 'CELULAR WHATS', value: 'client_phone' },
      { text: 'MODELO', value: 'vehicle_model.vehicle_model' },
      { text: 'SEGURO', value: 'life_insurance' },
      { text: 'LANCE', value: 'bid_fixed' },
      { text: 'OPÇÕES', value: 'actions', align: 'end' },
    ],

    routeOptions: {
      resource: 'list',
      method: 'post',
      params: { consult: true },
    },

    pageOptions: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
    },
    userData: {
      name: '',
      phone: '',
    },

    modalOptions: {
      termLgpdItem: {},
      loading: '',
      loading2: '',
      modal: false,
      idItem: '',
      editItem: false,
      disableItem: false,
      validItem: false,
      remakeItem: false,
      sendContract: false,
    },
  }),

  watch: {
    typeFilterSelected(n) {
      if (n === 'client') {
        this.filterOptions.client_name = true
        this.filterOptions.proposal_number = false
        this.filterOptions.cpf_cnpj = false
        this.filterOptions.vendor_name = false
      } else if (n === 'proposal') {
        this.filterOptions.client_name = false
        this.filterOptions.proposal_number = true
        this.filterOptions.cpf_cnpj = false
        this.filterOptions.vendor_name = false
      } else if (n === 'vendor_name') {
        this.filterOptions.client_name = false
        this.filterOptions.proposal_number = false
        this.filterOptions.cpf_cnpj = false
        this.filterOptions.vendor_name = true
      } else {
        this.filterOptions.client_name = false
        this.filterOptions.proposal_number = false
        this.filterOptions.cpf_cnpj = true
        this.filterOptions.vendor_name = false
      }
    },
  },

  created() {
    this.initialize()
  },

  methods: {

    async getListStore() {
      try {
        const response = await axiosIns.get('/api/v1/records/company/index')

        this.listStore = response.data.data
      } catch (error) {
        this.showMessage({
          title: 'Erro ao buscar a lista de lojas!',
          text: error,
          icon: 'error',
        })
      }
    },
    async getListRegion() {
      try {
        const response = await axiosIns.get('/api/v1/records/region/index')

        this.listRegion = response.data.data
      } catch (error) {
        this.showMessage({
          title: 'Erro ao buscar a lista de regiões!',
          text: error,
          icon: 'error',
        })
      }
    },

    async exportCsv(item) {
      const file = await this.getCsv(item)

      if (file) {
        saveAs(file)
      }
    },
    async getCsv() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')

      worksheet.columns = [
        { header: 'DATA', key: 'contract_date' },
        { header: 'PROPOSTA', key: 'proposal_number' },
        { header: 'CLIENTE', key: 'client_name' },
        { header: 'VENDEDOR', key: 'vendor_name' },
        { header: 'CELULAR', key: 'client_phone' },
        { header: 'MODELO', key: 'vehicle_model.vehicle_model' },
        { header: 'SEGURO', key: 'life_insurance' },
        { header: 'LANCE', key: 'bid_fixed' },
      ]

      this.termLgpdList.map(item => {
        worksheet.addRow([
          item.contract_date,
          item.proposal_number,
          item.client_name,
          item.vendor_name,
          item.client_phone,
          item.vehicle_model.vehicle_model ? item.vehicle_model.vehicle_model : '',
          item.life_insurance ? 'SIM' : 'NÃO',
          item.bid_fixed ? 'SIM' : 'NÃO',
        ])
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'termo_lgpd', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      })

      this.loaderExport = false

      return blob
    },

    initialize() {
      this.getDataUser()
      this.getListStore()
      this.getListRegion()
    },

    setTableData(data) {
      this.termLgpdList = data.data.data

      this.pageOptions.page = data.data.meta.current_page
      this.pageOptions.itemsPerPage = data.data.meta.per_page
      this.pageOptions.pageCount = data.data.meta.last_page
    },

    setBody(body) {
      this.bodyFilter = body
    },

    openModal(type) {
      const modal = {
        filter: () => { this.showFilter = true },

      }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error('The modal type is not valid')
    },

    async getTermLgpd() {
      this.isLoadingTableData = true
      this.termLgpdList = []

      await axiosIns
        .post('/api/v1/integration/lgpd_term/list', this.routeOptions.params)
        .then(res => {
          this.heightDynamic = '600px'
          res.data.data.map(value => {
            this.termLgpdList.push({
              id: value.id,
              proposal_number: value.proposal_number,

              // receipt_number: value.receipt_number,
              client_name: value.client_name,
              cpf_cnpj: value.cpf_cnpj,
              client_phone: value.client_phone,
              contract_date: value.contract_date,
              bid_fixed: value.bid_fixed,
              life_insurance: value.life_insurance,
              customer_subscription: value.customer_subscription,
              submit: value.submit,

              // email: value.email,
              vendor_name: value.vendor_name,
              conferred_by: value.conferred_by,
              vehicle_model: value.vehicle_model,
              observation: value.observation,
              term_refused: value.term_refused,
              code_validity_date: value.code_validity_date,
              auth_code: value.auth_code,
              term_revalidated: value.term_revalidated,
              validated_date: value.validated_date,
              vendor_cpf: value.vendor_cpf,
              phone_vendor: value.phone_vendor,
              valid_code: value.valid_code,
              wa_sent: value.wa_sent,
              term_status: value.term_status,
            })
          })
          this.pageOptions.page = res.data.meta.current_page
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
        })
        .catch(err => {
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
        .finally(() => {
          this.isLoadingTableData = false
        })
    },

    async sendingSignedDocto(item) {
      this.modalOptions.loading2 = item.id
      const data = {
        id: item.id,
      }
      await axiosIns
        .post('/api/v1/integration/lgpd_term/sending_signed_docto_client', data)
        .then(res => {
          this.closeModal()
          this.$swal({
            icon: 'success',
            title: 'Termo enviado.',
            text: `${res.data.text}`,
            showConfirmButton: false,
            timer: 3000,
          })
        })
        .catch(error => {
          this.closeModal()
          this.$swal({
            icon: 'error',
            title: 'Erro ao enviar termo!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })
        .finally(() => {
          this.modalOptions.loading2 = ''
        })
    },

    /* pagination methods */
    async updatePage() {
      this.isLoadingTableData = true

      this.termLgpdList = []
      await axiosIns
        .post(
          `/api/v1/integration/lgpd_term/find_term?page=${this.pageOptions.page}`,
          this.bodyFilter,
        )
        .then(res => {
          res.data.data.map(value => {
            this.termLgpdList.push({
              id: value.id,
              proposal_number: value.proposal_number,

              // receipt_number: value.receipt_number,
              client_name: value.client_name,
              cpf_cnpj: value.cpf_cnpj,
              client_phone: value.client_phone,
              contract_date: value.contract_date,
              bid_fixed: value.bid_fixed,
              life_insurance: value.life_insurance,
              customer_subscription: value.customer_subscription,
              submit: value.submit,

              // email: value.email,
              vendor_name: value.vendor_name,
              conferred_by: value.conferred_by,
              vehicle_model: value.vehicle_model,
              observation: value.observation,
              term_refused: value.term_refused,
              code_validity_date: value.code_validity_date,
              auth_code: value.auth_code,
              term_revalidated: value.term_revalidated,
              validated_date: value.validated_date,
              vendor_cpf: value.vendor_cpf,
              phone_vendor: value.phone_vendor,
              valid_code: value.valid_code,
              wa_sent: value.wa_sent,
              term_status: value.term_status,
            })
          })
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
        })
        .catch(err => {
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
        .finally(() => {
          this.isLoadingTableData = false
        })
    },

    /* filter methods */
    async filterQuickSearch() {
      this.isLoadingTableData = true

      this.routeOptions = {
        resource: 'filter',
        method: 'post',
        params: this.filterOptions,
      }
      this.termLgpdList = []
      await axiosIns
        .post('/api/v1/integration/lgpd_term/filter', this.routeOptions.params)
        .then(res => {
          res.data.data.map(value => {
            this.termLgpdList.push({
              id: value.id,
              proposal_number: value.proposal_number,

              // receipt_number: value.receipt_number,
              client_name: value.client_name,
              cpf_cnpj: value.cpf_cnpj,
              client_phone: value.client_phone,
              contract_date: value.contract_date,
              bid_fixed: value.bid_fixed,
              life_insurance: value.life_insurance,
              customer_subscription: value.customer_subscription,
              submit: value.submit,

              // email: value.email,
              vendor_name: value.vendor_name,
              conferred_by: value.conferred_by,
              vehicle_model: value.vehicle_model,
              observation: value.observation,
              term_refused: value.term_refused,
              code_validity_date: value.code_validity_date,
              auth_code: value.auth_code,
              term_revalidated: value.term_revalidated,
              validated_date: value.validated_date,
              vendor_cpf: value.vendor_cpf,
              phone_vendor: value.phone_vendor,
              valid_code: value.valid_code,
              wa_sent: value.wa_sent,
              term_status: value.term_status,
            })
          })
          this.pageOptions.page = res.data.meta.current_page
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
        })
        .catch(err => {
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
        .finally(() => {
          this.isLoadingTableData = false
        })
    },

    async filterReset() {
      this.isLoadingTableData = true

      this.termLgpdList = []
      this.routeOptions = {
        resource: 'list',
        method: 'post',
        params: { consult: true },
      }

      this.initialize()
    },

    /* modal methods */
    async editTermLgpd(item) {
      this.modalOptions.loading = item.id
      this.modalOptions.idItem = item.id
      this.modalOptions.editItem = true
      this.modalOptions.remakeItem = true
      this.modalOptions.sendContract = true
      await axiosIns
        .get(`/api/v1/integration/lgpd_term/edit/${item.id}`)
        .then(res => {
          const value = res.data.data
          this.modalOptions.termLgpdItem = {
            id: value.id,
            proposal_number: value.proposal_number,

            // receipt_number: value.receipt_number,
            client_name: value.client_name,
            cpf_cnpj: value.cpf_cnpj,
            client_phone: value.client_phone,
            contract_date: value.contract_date,
            bid_fixed: value.bid_fixed,
            life_insurance: value.life_insurance,
            customer_subscription: value.customer_subscription,
            submit: value.submit,

            // email: value.email,
            vendor_name: this.userData.name,
            conferred_by: value.conferred_by,
            vehicle_model: value.vehicle_model,
            observation: value.observation,
            term_refused: value.term_refused,
            code_validity_date: value.code_validity_date,
            auth_code: value.auth_code,
            term_revalidated: value.term_revalidated,
            validated_date: value.validated_date,
            vendor_cpf: value.vendor_cpf,
            phone_vendor: value.phone_vendor ? value.phone_vendor : '',
            valid_code: value.valid_code,
            wa_sent: value.wa_sent,
            term_status: value.term_status,
          }
          this.modalOptions.loading = ''
          this.modalOptions.modal = true
        })
        .catch(async err => {
          this.modalOptions.loading = ''
          await this.$swal({
            icon: 'error',
            title: 'Erro ao visualizar termo',
            showConfirmButton: false,
            text: err,
            timer: 3000,
          })
        })
    },

    async addTermLgpd() {
      this.clearModal()
      this.modalOptions.editItem = false
      this.modalOptions.validItem = false
      this.modalOptions.disableItem = false
      this.modalOptions.modal = true
    },

    async closeModal() {
      this.clearModal()
      this.modalOptions.modal = false
    },

    async clearModal() {
      Object.keys(this.modalOptions.termLgpdItem).forEach(key => {
        this.modalOptions.termLgpdItem[key] = ''
      })
    },

    async getDataUser() {
      await axiosIns.get('api/v1/records/user/logged_in_user')
        .then(res => {
          this.userData = {
            name: res.data.data.name,
            phone: res.data.data.cellphone,
          }
        })
    },
    async updatedTable() {
      await this.getTermLgpd()
    },

    clearFilter() {
      this.pageOptions.page = 0
      this.pageOptions.pageCount = 0
      this.pageOptions.itemsPerPage = 0

      this.termLgpdList = []

      this.bodyFilter = {}
    },
  },
}
</script>
